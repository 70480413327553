import { useState} from 'react';
import styles from '../styles/body.module.scss'
import {Box, TextField} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import btnStyles from '../styles/buttons.module.scss'
import FormHelperText from '@mui/material/FormHelperText';
import{BottomNavigation, BottomNavigationAction} from '@mui/material'

import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginValidateType)

export default function Witnessed({reportType, witnessed, setWitnessed,imgFile, setImgFile, file, setFile}){
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const [witnessError, setWitnessError] = useState(false);
    const [witnessText, setWitnessText] = useState('');

    const [imageError, setImageError] = useState(false);
    const [imageText, setImageText] = useState('');
    
    const handleWitnessChange = (newValue) => {
        setWitnessed(newValue);
    };

    const handlePhotoUploadChange = () => {
        setImageError(false);
        setImageText("");
        if (Array.isArray(file) && file.length) {
            const imageData = new FormData();
            for (var i = 0; i < file.length; i++) {
                imageData.append("files", file[i].file);
                if(file[i].file.size>10000000){
                    setImageError(true);
                    setImageText("Images cannot be greater than 10MB");
                    return false;
                }

            }

            setImgFile(imageData);
            
            
        }
        return true;
    };

    const nextPage = () => {

        setWitnessText('');
        setWitnessError(false);

        if (witnessed.length > 250) {
            setWitnessText('List of witnessed must be less than 250 characters');
            setWitnessError(true);
        }
        else {

            if (handlePhotoUploadChange()) {
                if (reportType === 'Accident/Injury') {
                    navigate("/accident_user_details");
                    //navigate("/accident_summary");

                }
                else {
                    navigate("/notes");
                }
            }
        }
    }

  return (
      <>
       <Box className={styles.questionH1Top} >
              <h1>Who witnessed the event?</h1>
              </Box>
              <Box className={styles.textField} >
              <FormControl fullWidth margin="normal" className={styles.field} error={witnessError}>
                 <TextField error={witnessError} value={witnessed} inputProps={{maxLength: 250}} helperText={`${witnessed.length}/${250}`} fullWidth id="outlined-basic" multiline rows={5} label="Witnesses (Optional)" variant="outlined" onChange={e => handleWitnessChange(e.target.value)}  />
                 
              </FormControl>
              </Box>
              <Box className={styles.questionH1} >
              <h1>Photo evidence</h1>
              </Box>
              <Box className={styles.textField} >
            <FormControl fullWidth margin="normal" className={styles.field} error={imageError} >
            <FormHelperText id="photoText">Once you have selected your first photo, tap 'Select or take a photo' again to upload up to 5 photos.</FormHelperText>
              <FilePond
                files={file}
                allowFileTypeValidation={true}
                acceptedFileTypes={['image/png', 'image/jpeg','application/pdf']}
                storeAsFile={true}
                allowMultiple={true}
                onupdatefiles={setFile}
                maxFiles={5}
                labelIdle='<span class="filepond--label-action">Select or take</span>  a photo'
                allowFileSizeValidation={true}
                maxFileSize={10000000}
                labelMaxFileSizeExceeded={'File is too large'}
                
                />
                <FormHelperText>{imageText}</FormHelperText>
            </FormControl>
            
            </Box>
            <Box className={btnStyles.navbuttons} >
              <Button startIcon={<ArrowBackIosNewIcon />} className={btnStyles.backBtn} size="large" variant="contained" onClick={() => goBack()}>Back</Button>
              <Button endIcon={<ArrowForwardIosIcon />} className={btnStyles.nextBtn} size="large" variant="contained"
                  onClick={() => nextPage()}
              >Next</Button>

          </Box>
          <Box className={btnStyles.bottomNavContainer}>
              <BottomNavigation sx={{
                  width: '100%',
                  position: 'fixed',
                  bottom: 0,
                  height:'70px',
                  zIndex: '1000'
              }} showLabels>
                  <BottomNavigationAction id="navBackButton" label='Back' icon={<ArrowBackIosNewIcon />} onClick={() => goBack()} />
                  <BottomNavigationAction id="navNextButton" label='Next' icon={<ArrowForwardIosIcon />} onClick={() => nextPage()} />
              </BottomNavigation>
          </Box>

      </>
      
  )
}
